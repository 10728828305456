import React, { useState as UseState } from "react";
import styles from "./styles.module.css";
import { LanguageUid } from "../../../../libs/enums";
import { API_BASE_URL } from "../../../../libs/generalSettings";
import UseTranslation from "next-translate/useTranslation";
import { PostArrayData } from "../../../../libs/api/rest";
import { IsNullOrEmpty } from "../../../../libs/utils";
import ShipmentTrackingPreview from "./modal";
import DeliveryTimes from "../delivery_times";
import DeliveryFee from "../delivery_fee";
import Link from "next/link";

var _error = {};

const ShipmentTracking = (props) => {
  const { mShipmentTrackingOpenOrClosed } = props;
  const { t } = UseTranslation("GetLanguageResourceResponse");

  const [code, setCode] = UseState("");
  const [cityId, setCityId] = UseState("");
  const [shipmentTracking, setShipmentTracking] = UseState();
  const [modalIsActive, setModalIsActive] = UseState(false);
  const [tabId, setTabId] = UseState(1);
  const [search, setSearch] = UseState(false);

  const onSetCode = (e) => {
    setCode({ ...code, [e.target.name]: e.target.value.toUpperCase() });
  };

  const onSetCityId = (e) => {
    setCityId({ ...cityId, [e.target.name]: e.target.value });
  };

  const onGetShipmentTracking = async () => {
    if (IsNullOrEmpty(code)) return;

    setSearch(true);
    let getCode = code.value.split(",");

    await PostArrayData(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/ShipmentTracking`,
      getCode
    ).then((result) => {
      try {
        if (result[0].errorState) {
          if (result[0].errorMessage === "KAYIT YOK") {
            _error.Message = result[0].errorMessage;
            setSearch(false);
            return;
          }

          setShipmentTracking(result);
          setModalIsActive(!modalIsActive);
          setSearch(false);

          _error.Message = "";
          document.body.style.overflow = "hidden";
        } else {
          _error.Message = result[0].errorMessage;

          setSearch(false);
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const onGetBody = () => {
    switch (tabId) {
      case 1:
        return (
          <>
            <div className={styles.header}>
              <h1>{t(LanguageUid.SHIPMENT_TRACKING_TITLE)}</h1>
            </div>

            <input
              onKeyUp={async (event) => {
                onSetCode(event);
                if (event.key == "Enter") {
                  event.preventDefault();
                  await onGetShipmentTracking();
                }
              }}
              type={"text"}
              name="value"
              placeholder={t(LanguageUid.SHIPMENT_TRACKING_INPUT_PLACEHOLDER)}
            />
            <button
              onClick={async () => {
                await onGetShipmentTracking();
              }}
            >
              {!search ? (
                t(LanguageUid.SHIPMENT_TRACKING_BUTTON)
              ) : (
                <i className="fa-solid fa-spinner"></i>
              )}
            </button>
          </>
        );
      case 2:
        return <DeliveryFee></DeliveryFee>;
      case 3:
        return <DeliveryTimes></DeliveryTimes>;
      case 4:
        return (
          <>
            <div className={styles.header}>
              <h1>{t(LanguageUid.NEAREST_BRANCH_TITLE)}</h1>
              <h2>{t(LanguageUid.NEAREST_BRANCH_SUBTITLE)}</h2>
            </div>

            <Link href={`https://enyakinptt.ptt.gov.tr`} passHref>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.nearestBranchButton}
              >
                {!search ? (
                  t(LanguageUid.NEAREST_BRANCH_TITLE)
                ) : (
                  <i className="fa-solid fa-spinner"></i>
                )}
              </a>
            </Link>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      {/* Start Modal */}
      {modalIsActive && (
        <ShipmentTrackingPreview
          model={shipmentTracking}
          isActive={setModalIsActive}
        ></ShipmentTrackingPreview>
      )}
      {/* End Modal */}

      {/* Start Mobil ShipmentTracking */}
      <div
        className={
          mShipmentTrackingOpenOrClosed
            ? styles["mShipmentTrackingCard"] + " " + styles["activeMS"]
            : styles["mShipmentTrackingCard"]
        }
      >
        <input
          onKeyUp={(event) => {
            onSetCode(event);
          }}
          type={"text"}
          name="value"
          placeholder={t(LanguageUid.SHIPMENT_TRACKING_INPUT_PLACEHOLDER)}
        />
        <button
          onClick={() => {
            onGetShipmentTracking();
          }}
        >
          {!search ? (
            t(LanguageUid.SHIPMENT_TRACKING_BUTTON)
          ) : (
            <i className="fa-solid fa-spinner"></i>
          )}
        </button>

        {IsNullOrEmpty(_error.Message) ? (
          ""
        ) : (
          <div className={styles.error}>{_error.Message}</div>
        )}
      </div>
      {/* End Mobil ShipmentTracking */}

      <div
        className={
          mShipmentTrackingOpenOrClosed
            ? styles["shipmentTrackingCard"] + " " + styles["activeMS"]
            : styles["shipmentTrackingCard"]
        }
      >
        <div className={styles.tabs}>
          <div
            className={styles.menu}
            style={{ backgroundColor: tabId === 1 ? "#fff" : null }}
          >
            <h1
              onClick={() => {
                setTabId(1);
                _error.Message = "";
              }}
            >
              {t(LanguageUid.SHIPMENT_TRACKING_TAB1)}
            </h1>
          </div>

          {/*      <div className={styles.menu}>
            <Link href="http://pttreklamws.ptt.gov.tr/HesapMakinesi/index_tr.html">
              <a target="_blank">{t(LanguageUid.SHIPMENT_TRACKING_TAB2)}</a>
            </Link>
          </div>  */}
          <div
            className={styles.menu}
            style={{ backgroundColor: tabId === 2 ? "#fff" : null }}
          >
            <h1
              target="_blank"
              onClick={() => {
                setTabId(2);
                _error.Message = "";
              }}
            >
              {t(LanguageUid.SHIPMENT_TRACKING_TAB2)}
            </h1>
          </div>

          <div
            className={styles.menu}
            style={{ backgroundColor: tabId === 3 ? "#fff" : null }}
          >
            <h1
              onClick={() => {
                setTabId(3);
                _error.Message = "";
              }}
            >
              {t(LanguageUid.SHIPMENT_TRACKING_TAB3)}
            </h1>
          </div>
          <div
            className={styles.menu}
            style={{ backgroundColor: tabId === 4 ? "#fff" : null }}
          >
            <h1
              onClick={() => {
                setTabId(4);
                _error.Message = "";
              }}
            >
              {t(LanguageUid.SHIPMENT_TRACKING_TAB4)}
            </h1>
          </div>
        </div>

        <div className={styles.body}>
          {onGetBody()}
          {IsNullOrEmpty(_error.Message) ? (
            ""
          ) : (
            <div className={styles.error}>{_error.Message}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShipmentTracking;
